<template>
  <el-dialog custom-class="minWidthLandAdd" :title="form.id ? '编辑土地' : '新增土地'" :visible.sync="visible" :close-on-click-modal="false" @close="onClose" draggable>
    <el-form ref="dataFormRef"  :model="form" :rules="dataRules" formDialogRef label-width="230px" v-loading="loading">
      <el-row :gutter="24">

        <el-col :span="12" class="mb20">
          <el-form-item label="供求类型" prop="landSupplyType">
            <el-radio-group v-model="form.landSupplyType" @change="clearTansWay">
              <el-radio border :label="0">出租</el-radio>
              <el-radio border :label="2">转让</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>


      <el-row :gutter="24">

        <el-col :span="12" class="mb10">
          <el-form-item label="土地性质" prop="ownerType">
            <el-select v-model="form.ownerType" placeholder="请选择土地性质">
              <el-option v-for="item in landTypeComputed" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>


        <el-col :span="24" class="mb10">
          <el-form-item label="土地用途" prop="landPurpose" style="display: inline-block;">
            <el-select v-model="form.landPurpose" placeholder="请选择土地用途" @change="clearSelect(0)">
              <el-option v-for="item in demandPurposeComputed" :key="item.value" :label="item.label" :value="item.value" :class="form.landSupplyType==0?'':(item.label=='工业场地' || item.label=='工业厂房' )?'hides': ''"> </el-option>
            </el-select>
          </el-form-item>


          <el-form-item label="" prop="subLandPurpose" label-width="10px" style="display: inline-block;" v-if="form.landPurpose=='other_land'">
            <el-input v-model="form.subLandPurpose" placeholder="其他用地" />
          </el-form-item>


          <el-form-item label="" prop="subLandPurpose" label-width="10px" style="display: inline-block;" v-if="false">
            <el-select v-model="form.subLandPurpose" placeholder="请选择土地二级用途" style="margin-left: 10px;">
              <el-option v-for="item in landPurposeItemOptionsComputed" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="24" class="mb10">
          <el-form-item label="土地用途2" prop="landPurpose2"  v-if="false" style="display: inline-block;">
            <el-select v-model="form.landPurpose2" placeholder="请选择土地一级用途" @change="clearSelect(1)">
              <el-option v-for="item in demandPurposeComputed" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="subLandPurpose2" label-width="10px" v-if="false"  style="display: inline-block;">
            <el-select v-model="form.subLandPurpose2" placeholder="请选择土地二级用途" style="margin-left: 10px;">
              <el-option v-for="item in landPurposeItemOptionsComputed2" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>

<!--        <el-col :span="12" class="mb10">-->
<!--          <el-form-item label="土地用途" prop="landPurpose">-->
<!--            <el-select v-model="form.landPurpose" placeholder="请选择土地用途">-->
<!--              <el-option v-for="item in landPurposeComputed" :key="item.value" :label="item.label" :value="item.value"> </el-option>-->
<!--            </el-select>-->
<!--&lt;!&ndash;            <el-cascader&ndash;&gt;-->
<!--&lt;!&ndash;                :options="landPurposeComputed"&ndash;&gt;-->
<!--&lt;!&ndash;                props="{muiltle}"&ndash;&gt;-->
<!--&lt;!&ndash;                clearable></el-cascader>&ndash;&gt;-->
<!--          </el-form-item>-->
<!--        </el-col>-->

      </el-row>

      <el-row :gutter="24">
        <el-col :span="12" class="mb10">
          <el-form-item label="土地面积" prop="landSize">
            <el-input placeholder="请输入土地面积" v-model="form.landSize">
              <template v-slot:append>平方米</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" class="mb10">
          <el-form-item label="所属行政区" prop="regionId">
            <el-select v-model="form.regionId" placeholder="请选择所属行政区">
              <el-option v-for="item in landAreaComputed" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" class="mb10">
          <el-form-item label="是否有地面建筑" prop="hasGroundBuilding">
            <el-radio-group v-model="form.hasGroundBuilding">
              <el-radio border :label="1">有</el-radio>
              <el-radio border :label="0">无</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>



        <el-col :span="12" class="mb10">
          <el-form-item label="建筑类型" prop="buildingType" v-show="form.hasGroundBuilding">
            <el-input v-model="form.buildingType" placeholder="请输入建筑类型" />
          </el-form-item>
        </el-col>

        <el-col :span="12" class="mb10">
          <el-form-item label-width="" label="建筑面积" prop="buildingSize" v-show="form.hasGroundBuilding">
            <el-input v-model="form.buildingSize" placeholder="请输入建筑面积" >2222
              <template v-slot:append>平方米</template>
            </el-input>
          </el-form-item>
        </el-col>

        <el-col :span="24" class="mb10">
          <el-form-item label="交易方式" prop="transactionWay">
            <el-select v-model="form.transactionWay" @change="transactionWayMsg" placeholder="请选择交易方式">
              <el-option v-for="item in transactionWayComputed" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12" class="mb10" v-if="form.isshowtype">
          <el-form-item label="转让价格" prop="price">
            <el-input v-model="form.price"  placeholder="请输入转让价格">
              <template v-slot:append>万元</template>
            </el-input>
          </el-form-item>
        </el-col>


        <el-col :span="16" class="mb10">
          <el-form-item label="土地描述" prop="desc">
            <el-input type="textarea" placeholder="请输入土地描述，限100字以内" v-model="form.desc"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="16" class="mb10">
          <el-form-item label="VR" prop="vr">
            <el-input  placeholder="请输入VR地址" v-model="form.vr"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" class="mb10">
          <el-form-item label="容积率" prop="volumeRate">
            <el-input v-model="form.volumeRate" placeholder="请输入容积率" />
          </el-form-item>
        </el-col>

        <el-col :span="12" class="mb10">
          <el-form-item label="土地位置" prop="landPosition">
            <el-input v-model="form.landPosition" placeholder="请输入土地位置" />
          </el-form-item>
        </el-col>
        <rulenotice-dialog ref="rulenoticeRef" />
        <map-dialog ref="mapDialogRef" />
        <el-col :span="12" class="mb10">
          <el-form-item label="地图标记" prop="volumeRate">
            <el-button type="primary"  @click="openMapDialog">打开地图</el-button>
          </el-form-item>
        </el-col>


        <el-col :span="24" class="mb10">
          <el-divider>权证信息</el-divider>
        </el-col>
        <el-col :span="24" class="mb10" v-if="false">
          <el-form-item label="是否匿名" prop="anonymous">
            <el-radio-group v-model="form.anonymous">

              <el-radio border :label="0">不匿名  <template v-slot:append>万元</template></el-radio>
              <el-radio border :label="1">匿名</el-radio>
              <el-radio-button disabled  >匿名仅展示土地交易中心电话，将会有土地交易中心工作人员和您联系</el-radio-button>
            </el-radio-group>

          </el-form-item>
        </el-col>

        <el-col :span="12" class="mb10">
          <el-form-item label="联系人" prop="contact" >
            <el-input v-model="form.contact"  placeholder="请输入联系人"/>
          </el-form-item>
        </el-col>

        <el-col :span="12" class="mb10">
          <el-form-item label="联系人手机号" prop="phoneNumber">
            <el-input v-model="form.phoneNumber" placeholder="请输入联系人手机号" />
          </el-form-item>
        </el-col>


        <el-col :span="12" class="mb10" v-if="form.isshowtype">
          <el-form-item label="统一社会信用代码" prop="unifiedSocialCreditCode" >
            <el-input v-model="form.unifiedSocialCreditCode"  placeholder="请输入统一社会信用代码"/>
          </el-form-item>
        </el-col>

        <el-col :span="12" class="mb10">
          <el-form-item label="联系人地址" prop="contactAddress">
            <el-input v-model="form.contactAddress" placeholder="请输入联系人地址" />
          </el-form-item>
        </el-col>

        <el-col :span="12" class="mb10" v-if="form.isshowtype">
          <el-form-item label="土地权属单位" prop="ownerOrg">
            <el-input v-model="form.ownerOrg" placeholder="请输入土地权属单位" />
          </el-form-item>
        </el-col>

        <el-col :span="12" class="mb10" v-if="form.isshowtype">
          <el-form-item label="权证类型" prop="licenseNoType">
            <el-select v-model="form.licenseNoType" placeholder="请选择权证类型">
              <el-option v-for="item in land_license_type" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12" class="mb10" v-if="form.isshowtype">
          <el-form-item label="使用权截止日期" prop="landDeadLine">
            <el-date-picker type="date" placeholder="请选择使用权截止日期" :value-format="dateStr" v-model="form.landDeadLine" ></el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="12" class="mb10" v-if="form.isshowtype">
          <el-form-item label="权证编号" prop="licenseNo">
            <div style="display: flex; flex: 1; flex-direction: column; row-gap: 12px">
              <div style="display: flex; flex-direction: row; column-gap: 12px" v-for="(item, index) in form.licenseNo" :key="index">
                <el-input v-model="form.licenseNo[index]" placeholder="请输入权证编号" style="flex: 1" />
                <el-button type="primary" icon="el-icon-circle-plus-outline" @click="onAddlicenseNoClick(index)" v-if="index === 0">
                </el-button>
                <el-button type="danger" icon="el-icon-remove-outline"  @click="onAddlicenseNoClick(index)" v-if="index !== 0">
                </el-button>
              </div>
            </div>
          </el-form-item>
        </el-col>

        <el-col :span="24" class="mb10">
          <el-divider>其他</el-divider>
        </el-col>

        <el-col :span="24" class="mb10">
          <el-form-item label="土地图片" prop="landPics">
            <div style="display: flex; flex-direction: row; column-gap: 12px">
              <ImageUpload v-for="(pic, index) in uploadImage" :key="pic" :imageUrl="uploadImage[index]" @childUpload="imageGetData" height="120px" width="120px">
                <template #empty>
                  <span>请上传土地图片</span>
                </template>
              </ImageUpload>
            </div>
          </el-form-item>
        </el-col>


        <el-col :span="24" class="mb20"  >
          <el-divider>附件信息</el-divider>
        </el-col>
        <el-col :span="6" class="mb20">
        </el-col>
        <el-col :span="14" class="mb20" v-if="needOtherFiles">
          注意:如是国有改制企业转让，必须提交国资委意见书;  如是国有企事业单位，必须提交上级主管部门意见书
        </el-col>
        <el-col :span="2" class="mb20" v-if="needOtherFiles">
          <el-button color="#626aef"  @click="downloadDemo" >
            下载合同模板
          </el-button>
        </el-col>
        <el-col :span="12" :offset="7" class="mb10" >
          <el-form-item  label-width="250px"  label="不动产产权证(原土地证、房产证)" prop="identityFiles">
            <upload @childUpload="parentGetData"  @on-preview="previewFile" name="identityFiles" height="20px" width="120px"/>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="7"  class="mb10" v-if="needOtherFiles">
          <el-form-item  label-width="250px"  label="身份证明（营业执照、机构代码、法人证明、法人身份证）" prop="businessFiles">
            <upload @childUpload="parentGetData"  @on-preview="previewFile" name="businessFiles" height="20px" width="120px"/>
          </el-form-item>
        </el-col>

        <el-col :span="12" :offset="7"  class="mb10" v-if="needOtherFiles && false">
          <el-form-item  label-width="250px"  label="机构代码" prop="companyCode">
            <upload @childUpload="parentGetData"  @on-preview="previewFile" name="companyCode" height="20px" width="120px" />
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="7"  class="mb10" v-if="needOtherFiles && false">
          <el-form-item label-width="250px"  label="法人证明" prop="farenZhengming">
            <upload @childUpload="parentGetData"  @on-preview="previewFile" name="farenZhengming" height="20px" width="120px"  />
          </el-form-item>
        </el-col>

        <el-col :span="12" :offset="7"  class="mb10" v-if="needOtherFiles && false">
          <el-form-item   label-width="250px"  label="法人身份证(复印件加盖单位印章)" prop="farenIdCard">
            <upload @childUpload="parentGetData"  @on-preview="previewFile" name="farenIdCard" />
          </el-form-item>
        </el-col>
       <el-col :span="12" :offset="7"  class="mb10" >
          <el-form-item label-width="250px"  label="不动产登记信息卡" prop="identityCards">
            <upload @childUpload="parentGetData"  @on-preview="previewFile" name="identityCards" />
          </el-form-item>
        </el-col>

        <el-col :span="12"  :offset="7"  class="mb10" v-if="needOtherFiles">
          <el-form-item  label-width="250px"  label="企业章程" prop="companyFiles">
            <upload @childUpload="parentGetData"  @on-preview="previewFile" name="companyFiles" />
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="7"   class="mb10" v-if="needOtherFiles">
          <el-form-item  label-width="250px"   label="股东会决议" prop="shareholderFiles">
            <upload @childUpload="parentGetData"  @on-preview="previewFile" name="shareholderFiles" />
          </el-form-item>
        </el-col>

        <el-col :span="12" :offset="7"   class="mb10" v-if="needOtherFiles">
          <el-form-item   label-width="250px" label="其他" prop="otherFiles">
            <upload @childUpload="parentGetData"  @on-preview="previewFile" name="otherFiles" />
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="7"   class="mb10" v-if="needOtherFiles">
          <el-form-item  label-width="250px" label="投资审计报告" prop="auditReport">
            <upload @childUpload="parentGetData"  @on-preview="previewFile" name="auditReport" />
          </el-form-item>
        </el-col>

        <el-col :span="12"  :offset="7"  class="mb10" v-if="needOtherFiles">
          <el-form-item label-width="250px" label="宗地评估报告" prop="landReport">
            <upload @childUpload="parentGetData"  @on-preview="previewFile" name="landReport" />
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="7"   class="mb10" v-if="needOtherFiles">
          <el-form-item label-width="250px" label="经办人委托书及其身份证明复印件" prop="proxyFiles">
            <upload @childUpload="parentGetData"  @on-preview="previewFile" name="proxyFiles" />
          </el-form-item>
        </el-col>

        <el-col :span="12" :offset="7"   class="mb10" v-if="needOtherFiles">
          <el-form-item label-width="250px" label="国有企业主管部门同意转让的批准文件" prop="permitFiles">
            <upload @childUpload="parentGetData"  @on-preview="previewFile" name="permitFiles" />
          </el-form-item>
        </el-col>
      </el-row>
      <facerecognition-dialog ref="facerecognitionRef"   />
    </el-form>
    <template #footer v-if="!form.id">
			<span class="dialog-footer">
				<el-button @click="onClose">取消</el-button>
				<el-button type="primary" @click="onverify" :disabled="loading">确认</el-button>
			</span>
    </template>
  </el-dialog>
</template>

<script setup  name="needAdd">
import { useDict } from '@/hooks/dict';
import { useNewDict } from '@/hooks/newDict';
import { reactive, watch, ref, nextTick, computed, defineAsyncComponent } from 'vue'
import {Message} from 'element-ui'
import {getObj, addObj, putObj,setidCardInfo,getidCardInfo} from '@/service/CommonService';
import { rule } from '@/utils/validator';

const emit = defineEmits(['refresh']);
const ImageUpload = defineAsyncComponent(() => import('@/components/Upload/Image.vue'));
const Upload = defineAsyncComponent(() => import('@/components/Upload/NewFile.vue'));

const mapDialog = defineAsyncComponent(() => import('./tmap.vue'));

const mapDialogRef = ref();

const rulenoticeDialog = defineAsyncComponent(() => import('./Rulenotice.vue'));
const rulenoticeRef = ref();


const facerecognitionDialog = defineAsyncComponent(() => import('./FaceRecognition.vue'));
const facerecognitionRef = ref();


const { transaction_way, land_area,land_type} = useDict(
    'transaction_way',
    'land_area',
    'land_type'

);
const {
  land_owner_type, land_license_type, yes_no_type } = useDict(
    'land_owner_type',
    'land_license_type',
    'yes_no_type'
);
const landAreaComputed = computed(() => {
  return useDict('land_area').land_area.value;
}) ;

const demandPurposeComputed = computed(() => {
  return useDict('demand_purpose').demand_purpose.value;
});



const landTypeComputed = computed(() => {
  return  useDict('land_type').land_type.value;
});
const landPurposeItemOptionsComputed = computed(() => {
  const options = useDict(form.landPurpose);
  return options[form.landPurpose].value;
});
const landPurposeItemOptionsComputed2 = computed(() => {
  const options = useDict(form.landPurpose2);
  return options[form.landPurpose2].value;
});


const clearSelect = (index) => {
  index === 0 ? form.subLandPurpose = '' : form.subLandPurpose2='';
};


const openMapDialog = () => {
  mapDialogRef.value.openDialog(form.landPosition, form.geoInfo, (data) => {
    form.geoInfo = data;
  });
};


const needOtherFiles = computed(() => {

  let otherFiles = false;
  if (form.transactionWay === 'listing' || form.transactionWay === 'auction') {
    otherFiles = true;
  }
  return otherFiles;
})

const transactionWayComputed = computed(() => {

  let option = transaction_way.value.slice(0,3);
  if (form.landSupplyType === 0) {
    option = transaction_way.value.slice(3,4);
    console.log(option);
  }

  return option;
})

// 定义变量内容
const dataFormRef = ref();
const visible = ref(false);
const loading = ref(false);
const dateStr = 'yyyy-MM-dd HH:mm:ss';

const initialState = () => {
  return {
    userverify:'',
    id: '',
    title: '',
    landSupplyType: '',
    ownerType: '',
    transactionWay: '',
    ownerOrg: '',
    licenseNoType: '',
    licenseNo: [''],
    desc: '',
    contact: '',
    anonymous: 0,
    hasGroundBuilding: 1,
    buildingType: '',
    buildingSize: '',
    phoneNumber: '',
    vr: '',
    landSize: '',
    landPurpose: '',
    subLandPurpose: '',
    landPurpose2: '',
    subLandPurpose2: '',
    isPublished: 1,
    transactionStatus: 'pending',
    landDeadLine: '',
    identityFiles:[],
    landReport:[],
    identityCards: [],
    permitFiles: [],
    auditReport: [],
    businessFiles: [],
    companyCode: [],
    farenZhengming: [],
    companyFiles: [],
    farenIdCard: [],
    shareholderFiles: [],
    proxyFiles: [],
    regionId: '',
    landPosition: '',
    commitTime: '',
    landDemand: '',
    landPics: '',
    recommended: 0,
    volumeRate: '',
    price: 0.0,
    gujia: '',
    updateBy: '',
    contactAddress: '',
    isshowtype: true,
    unifiedSocialCreditCode: '',
    updateTime: '',
    auditStatus: '',
    geoInfo: [],
  };
};

const previewFile = (file) => {
  window.open(file.url);
};
const xzq = {
  'jinyang': '旌阳区',
  'jingkai': '经开区',
  'luojiang': '罗江区',
  'guanghan': '广汉市',
  'shifang': '什邡市',
  'mianzhu': '绵竹市',
  'zhongjiang': '中江县',
};
const tdyt = {
  0: '出租',
  2: '转让',
};

const demandPurposeItemOptionsComputed = computed(() => {
  const options = useDict(form.demandPurpose);
  return options[form.demandPurpose].value;
});
const onClose= () =>{

  stop();
  window.location.reload()
}
const downloadDemo = () => {
  let url = './../../assets/img/2.png?attname=test.pdf';
  window.open(url);

};
const parentGetData = (value,name) => {
  form[name] = value;
  dataFormRef.value.validateField(name);
};

// 提交表单数据
const form = reactive(initialState());
const uploadImage = ref(form.landPics.split(','));
const validateLicenseNo = (v1, v2) => {
  var result = v2.filter((i) => i.length);
  return false;
};

 const validateArray = async (rule, value, callback) => {
  let filedName = ''
  let message = ''
  value = value.filter((i) => i.length)
  if (typeof rule.message !== 'undefined' && rule.message !== '') {
    message = rule.message
  }
  if (value.length === 0) {
    if (message) {
      callback(new Error(message))
    } else {
      callback(new Error('注意：请填写' + filedName))
    }
  }

};
const validateSub = async (rule, value, callback) => {
  if (form.landPurpose2 && !value){
    callback(new Error(rule.message));
  }
}

const validatePrice = async (rule, value, callback) => {
  // console.log(value,typeof value , !isNaN(value));
  let exp = /^[+]?\d*(\.\d*)?(e[+-]?\d+)?$/;
  if(form.transactionWay === "auction"  || form.transactionWay ==="listing") {
      if (!exp.test(value) || value <= 0 ) {
        callback(new Error('请输入价格'));
      }
  }

}

const validateGujia = async (rule, value, callback) => {
  // console.log(value,typeof value , !isNaN(value));
  let exp = /^[+]?\d*(\.\d*)?(e[+-]?\d+)?$/;
  if (!exp.test(value) || value <= 0 ) {
    callback(new Error('请输入土地估价'));
  }

}

const clearTansWay = () => {
  console.log(demandPurposeComputed)
  if(form.landSupplyType!=0)
  {
    console.log(form.landPurpose);
    if(form.landPurpose=='transportation_land' || form.landPurpose=='water_land')
    {
      form.landPurpose='';
    }
  }
  form.transactionWay = '';
};


const imageGetData = (value) => {
  let tempImages = [];
  value.forEach((item) => {
    tempImages.push(item.url);
  })
  tempImages = Array.from(new Set(tempImages));
  form.landPics = tempImages.join(",");
  dataFormRef.value.validateField('landPics');
};
const dataRules = ref({
  // title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
  landSupplyType: [{ required: true, message: '请选择土地供应类型', trigger: 'change' }],
  landSize: [
    { required: true, message: '请输入土地面积', trigger: 'blur' },
    { validator: rule.number, trigger: 'blur' },
  ],
  landPurpose: [{ required: true, message: '请选择土地一级用途', trigger: 'change' }],
  landPurpose2: [{ required: false, message: '请选择土地一级用途', trigger: 'change' }],
  subLandPurpose: [{ required: true, message: '请选择土地二级用途', trigger: 'change' }],
  subLandPurpose2: [{ required: false, validator: validateSub, message: '请选择土地二级用途', trigger: 'change' }],
  regionId: [{ required: true, message: '请选择所属行政区', trigger: 'change' }],
  landPosition: [{ required: true, message: '请输入土地位置', trigger: 'blur' }],
  ownerType: [{ required: true, message: '请选择土地性质', trigger: 'change' }],
  orgtype: [{ required: true, message: '请选择土地权属单位类型', trigger: 'change' }],
  ownerOrg: [{ required: true, message: '请输入土地权属单位', trigger: 'change' }],
  hasGroundBuilding: [{ required: true, message: '请选择有无地上建筑', trigger: 'change' }],
  licenseNoType: [{ required: true, message: '请选择权证类型', trigger: 'change' }],
  licenseNo: [{ required: true, validator: validateArray, message: '请输入权证编号', trigger: 'blur' }],
  landDeadLine: [{ required: true, message: '请选择土地使用期限', trigger: 'blur' }],
  transactionWay: [{ required: true, message: '请选择交易方式', trigger: 'change' }],
  // transactionStatus: [{ required: true, message: '请选择交易状态', trigger: 'change' }],
  contact: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
  landPics: [{ required: true, message: '请至少上传1张土地图片', trigger: 'change' }],
  userverify: [{ required: true, message: '请先进行人脸识别', trigger: 'blur' }],
  identityFiles: [{ required: true, message: '请上传不动产权证', trigger: 'change' }],
  identityCards: [{ required: true, message: '请上传不动产信息登记卡', trigger: 'change' }],
  businessFiles: [{ required: true, message: '请上传营业执照', trigger: 'change' }],
  companyCode: [{ required: true, message: '请上传机构代码', trigger: 'change' }],
  farenZhengming: [{ required: true, message: '请上传法人证明', trigger: 'change' }],
  farenIdCard: [{ required: true, message: '请上传法人身份证', trigger: 'change' }],
  companyFiles: [{ required: true, message: '请上传企业章程', trigger: 'change' }],
  shareholderFiles: [{ required: true, message: '请上传股东会决议', trigger: 'change' }],
  unifiedSocialCreditCode: [{ required: true, message: '请输入统一社会信用代码', trigger: 'change' }],
  contactAddress: [{ required: true, message: '请输入联系人地址', trigger: 'change' }],

  phoneNumber: [
    { required: true, message: '请输入联系人手机号', trigger: 'blur' },
  ],
  price: [{required: false,validator: validatePrice, trigger: 'change' }],
  gujia: [{required: true,message: '请输入土地估价',validator: validateGujia, trigger: 'change' }],
});



// 打开弹窗
const openDialog = (id) => {
  visible.value = true;
  form.id = '';
  nextTick(() => {
    dataFormRef.value?.resetFields();
    Object.assign(form, initialState());

  });

  // 获取landInfo信息
  if (id) {
    form.id = id;
    getlandInfoData(id);
  }

};

const onAddlicenseNoClick = (index) => {
  if (index === 0) {
    form.licenseNo.push('');
  } else {
    form.licenseNo.splice(index, 1);
  }
};
const transactionWayMsg = (val) => {
  //Message.success('当前交易方式为 : ' + val);
  if(val=="auction"  || val=="listing")
  {
    form.userverifycbx=false;
    rulenoticeRef.value.openDialog('','', (data) => {
      console.log(data);
      form.userverifycbx=data;
      if (!data) {
        form.transactionWay = '';
      }
    });
  }
  //auction
  //listing
};


const lunxunData = () => {
  if( forms.timer ==null)
  {
    forms.timer = window.setInterval(() => {
      setTimeout(() => {
        checkQrCode()
      }, 0)
    }, 3000)

  }

}

const  checkQrCode  = async() =>  {

  const res = await getidCardInfo();

  // window.console.log(res)
  if (res.data.checked === 1) {
    // 803表示登录成功，以下做登录成功后的事件
    forms.userverify=true;
    window.console.log('登录成功')
    form.userverify='1';
    stop()
    onSubmit();
  } else if (res.data.code === 800) {
    // 803表示二维码过期
    window.console.log('二维码过期')
    // 开启css覆盖当前二维码
    // 停止轮询
    stop()
  }
}

const forms = reactive({
  timer:null,
  userverify:false,
  userverifycbx:false
})
const stop  = () => {
  window.clearInterval(forms.timer)
  clearInterval(forms.timer);
  forms.timer = null
}

const onverify= async () =>
{

  const valid = await dataFormRef.value.validate().catch(() => {});
  // console.log(valid);
  if (!valid) return false;
  if(!form.userverifycbx  &&  (form.transactionWay=="auction"  ||  form.transactionWay=="listing"))
  {
    transactionWayMsg(form.transactionWay)
    return  Message.error("请勾选规则");
  }

  if(  localStorage.getItem("login_type")!="ca")
  {
    lunxunData();
    facerecognitionRef.value.openDialog('','', (data) => {
      stop();
    });
  }else {
    onSubmit();
  }

}


// 提交
const onSubmit = async () => {


  const valid = await dataFormRef.value.validate().catch(() => {});
  // console.log(valid);
  if (!valid) return false;

  if(!form.userverifycbx  &&  (form.transactionWay=="auction"  ||  form.transactionWay=="listing"))
  {
    transactionWayMsg(form.transactionWay)
    return  Message.error("请勾选规则");
  }

  try {
    var licenseNo = form.licenseNo.filter((i) => i.length).join(',');
    const saveForm = JSON.parse(JSON.stringify(form));
    // console.log(saveForm)
    saveForm.licenseNo = licenseNo;
    saveForm.title = xzq[saveForm.regionId];
    demandPurposeComputed.value.forEach((elem) => {
      if (elem.value === saveForm.landPurpose) {
        saveForm.title += elem.label;
      }
    });
    land_type.value.forEach((elem) => {
      if (elem.value === saveForm.land_type) {
        saveForm.title += elem.label;
      }
    });

    transaction_way.value.forEach((elem) => {
      if (elem.value === saveForm.transactionWay) {
        saveForm.title += elem.label;
      }
    });
    saveForm.title += tdyt[saveForm.landSupplyType];
    loading.value = true;
    form.id ? await putObj(saveForm) : await  addObj(saveForm);
    facerecognitionRef.value.closeDialog();
    Message.success(form.id ? '土地修改成功' : '土地发布成功');
    visible.value = false;
    emit('refresh');
  } catch (err) {
    Message.error(err.msg);
  } finally {
    loading.value = false;
    window.location.reload();
  }
};

// 初始化表单数据
const getlandInfoData = (id) => {
  // 获取数据
  loading.value = true;
  getObj(id).then((res) => {
    Object.assign(form, res.data);
    form.licenseNo = res.data.licenseNo ? res.data.licenseNo.split(',') : [''];
  }).finally(() => {
    loading.value = false;
  });
};

// 暴露变量
defineExpose({
  openDialog,
  stop
});
</script>

<style>
.minWidthLandAdd{
  min-width: 1260px !important;
}
.hides{
  display: none;
}
</style>
